<template>
  <b-tabs
    content-class="mt-1"
  >
    <!-- This tabs content will always be mounted -->
    <b-tab
      lazy
    >
      <template #title>
        <feather-icon icon="TrendingUpIcon" />
        <span>{{ t('Платежи') }}</span>
      </template>

      <b-list-group
        horizontal="md"
        class="pb-2"
      >
        <div
          v-for="(item, key) in $store.state['app-transaction'].kassSums"
          :key="key"
        >
          <b-list-group-item
            class="p-0"
            :active="$store.state['app-transaction'].activeKassa==key"
            style="border-radius: 0.628rem !important;box-shadow: rgb(76 78 100 / 22%) 0px 2px 10px 0px !important;"
            @click="runTransaction(key)"
          >
            <statistic-card-vertical
              :color="item.balance>0 ? 'success' : 'danger'"
              icon="ArchiveIcon"
              :statistic="roundUp(item.balance)"
              :statistic-title="item.account_name"
            />
          </b-list-group-item>

        </div>
        <!-- <b-list-group-item class="p-0">
          <statistic-card-vertical
            color="success"
            icon="ArchiveIcon"
            statistic="65000"
            statistic-title="Kassa 2"
          />
        </b-list-group-item>
        <b-list-group-item class="p-0">
          <statistic-card-vertical
            color="primary"
            icon="ArchiveIcon"
            statistic="0"
            statistic-title="Kassa kassa 2"
          />
        </b-list-group-item> -->
      </b-list-group>

      <payments-list />
    </b-tab>
    <!-- This tabs content will not be mounted until the tab is shown -->
    <!-- and will be un-mounted when hidden -->
    <b-tab
      lazy
    >
      <template #title>
        <feather-icon icon="CircleIcon" />
        <span>{{ t('Взаиморасчёты') }}</span>
      </template>

      <b-list-group
        horizontal="md"
        class="pb-2"
      >
        <div
          v-for="(item, key) in $store.state['app-transaction'].settlements"
          :key="key"
        >
          <b-list-group-item
            class="p-0"
            :active="$store.state['app-transaction'].activeSettle==key"
            @click="runSettle(key)"
          >
            <statistic-card-vertical
              :color="item.balance>0 ? 'success' : 'danger'"
              icon="ArchiveIcon"
              :statistic="roundUp(item.balance)"
              :statistic-title="item.types"
            />
          </b-list-group-item>

        </div>
        <!-- <b-list-group-item class="p-0">
          <statistic-card-vertical
            color="success"
            icon="ArchiveIcon"
            statistic="65000"
            statistic-title="Kassa 2"
          />
        </b-list-group-item>
        <b-list-group-item class="p-0">
          <statistic-card-vertical
            color="primary"
            icon="ArchiveIcon"
            statistic="0"
            statistic-title="Kassa kassa 2"
          />
        </b-list-group-item> -->
      </b-list-group>
      <state-list />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { onUnmounted, reactive } from '@vue/composition-api'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
// Notification
// import { useToast } from 'vue-toastification/composition'
import store from '@/store'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import paymentsList from './transaction-list/TransactionList.vue'
import stateList from './transaction-list-2/TransactionList.vue'

import transactionStoreModule from './transactionStoreModule'

export default {
  components: {
    BTabs,
    BTab,
    // BAlert,
    // BCard,
    // BCol,
    // BRow,
    BListGroup,
    BListGroupItem,
    paymentsList,
    stateList,
    StatisticCardVertical,
  },
  setup() {
    const { t } = useI18nUtils()
    // Use toast
    // const toast = useToast()
    const qanaqadur = reactive([])

    const TRANSACTION_APP_STORE_MODULE_NAME = 'app-transaction'

    // Register module
    if (!store.hasModule(TRANSACTION_APP_STORE_MODULE_NAME)) store.registerModule(TRANSACTION_APP_STORE_MODULE_NAME, transactionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRANSACTION_APP_STORE_MODULE_NAME)) store.unregisterModule(TRANSACTION_APP_STORE_MODULE_NAME)
    })
    console.log(store.state[TRANSACTION_APP_STORE_MODULE_NAME])

    // toast({
    //   component: ToastificationContent,
    //   props: {
    //     title: 'Успешно',
    //     icon: 'CheckCircleIcon',
    //     variant: 'success',
    //     text: 'Вы успешно нажали на кнопку сохранить.',
    //   },
    // })

    return {
      t,
      qanaqadur,
      runTransaction(key) {
        store.commit('app-transaction/UPDATE_ACTIVE_KASSA', key)
      },
      runSettle(key) {
        store.commit('app-transaction/UPDATE_ACTIVE_SETTLE', key)
      },
      roundUp(num) {
        const x = num ? (Math.round(num * 10) / 10) : 0
        const parts = x.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        return parts.join('.')
      },
    }
  },
}
</script>
