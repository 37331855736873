<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ t('Entries') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button variant="primary" :to="{ name: 'apps-transaction-add' }">
            {{ t('Add transaction') }}
          </b-button>
        </b-col>
        <b-col cols="12" md="4">
          <!-- datepicker -->
          <div class="d-flex align-items-center">
            <feather-icon icon="CalendarIcon" size="16" />
            <flat-pickr
              v-model="rangePicker"
              :config="{ mode: 'range' }"
              class="form-control flat-picker bg-transparent border-0 shadow-none"
              placeholder="YYYY-MM-DD"
              @on-change="flatPickerChange"
            />
          </div>
          <!-- datepicker -->
        </b-col>
        <!-- Search -->
        <b-col cols="12" md="4">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchTransaction"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      :busy="!fetchTransaction"
      :filter="$store.state['app-transaction']"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>{{ t('Загрузка') }}...</strong>
        </div>
      </template>

      <!-- <template #head(status)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template> -->

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link
          :to="{
            name: 'apps-transaction-edit',
            params: { id: data.item.parent_trans_id ? data.item.parent_trans_id : data.item.id }
          }"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template>

      <!-- Column: sale Status -->
      <template #cell(status)="data">
        <!-- <b-avatar
          :id="`invoice-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.status).variant}`"
        >
        </b-avatar> -->
        <b-badge
          :id="`invoice-row-${data.item.id}`"
          pill
          :variant="
            `light-${
              resolveInvoiceStatusVariantAndIcon(data.item.status).variant
            }`
          "
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon"
          />
          {{ data.item.status }}
        </b-badge>
        <b-tooltip :target="`invoice-row-${data.item.id}`" placement="top">
          <p class="mb-0">
            {{ data.item.status }}
          </p>
          <!-- <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p> -->
        </b-tooltip>
      </template>

      <!-- Column: Supplier -->
      <template #cell(type)="data">
        <b-badge
          pill
          :variant="
            `light-${
              data.item.type === 'income'
                ? 'success'
                : data.item.type === 'expense'
                  ? 'danger'
                  : 'primary'
            }`
          "
        >
          {{ data.item.type }}
        </b-badge>
      </template>

      <template #cell(amount)="data">
        <b-badge
          pill
          class="text-nowrap"
          :variant="
            `light-${
              data.item.type === 'income'
                ? 'success'
                : data.item.type === 'expense'
                  ? 'danger'
                  : 'primary'
            }`
          "
        >
          {{ roundUp(data.item.amount) }}
        </b-badge>
      </template>

      <!-- Column: Warehouse -->
      <template #cell(warehouse)="data">
        <b-media vertical-align="center">
          <!-- <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.name+data.item.type)"
              :variant="`light-${resolveClientAvatarVariant(data.item.status)}`"
            />
          </template> -->
          <span class="font-weight-bold d-block text-nowrap">
            {{ `${data.item.name} ${data.item.type}` }}
          </span>
          <small class="text-muted">{{
            `${data.item.city} ${data.item.address}`
          }}</small>
        </b-media>
      </template>

      <!-- Column: Responsible -->
      <template #cell(responsible)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(`${data.item.firstName} ${data.item.lastName}`)"
              :variant="`light-${resolveClientAvatarVariant(data.item.status)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ `${data.item.firstName} ${data.item.lastName}` }}
          </span>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(order_date)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Date -->
      <template #cell(date)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Date -->
      <template #cell(name)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Date -->
      <template #cell(subname)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(balance)="data">
        <b-badge
          pill
          class="text-nowrap"
          :variant="`light-${data.value > 0 ? 'success' : 'danger'}`"
        >
          {{ roundUp(data.value) }}
        </b-badge>
      </template>

      <!-- Column: Balance -->
      <template #cell(rate)="data">
        <span class="text-nowrap">
          {{ data.value > 1 ? roundUp(data.value) : '' }}
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(commision)="data">
        <span class="text-nowrap">
          {{ data.item.rate > 1 ? roundUp(data.value) : '' }}
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(account_name)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- <template #cell(subname)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <template #cell(comments)="data">
        <div class="text-nowrap">
          <span :id="`comment-row-${data.item.id}`">
            {{
              data.item.comments && data.item.comments.length > 10
                ? data.item.comments.slice(0, 10) + '..'
                : data.item.comments
            }}
          </span>
          <b-tooltip
            :title="data.item.comments"
            :target="`comment-row-${data.item.id}`"
          />
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-send-icon`"
            icon="SendIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'apps-transaction-add', params: { id: data.item.id }})"
          />
          <b-tooltip
            :title="t('Sell Sale')"
            class="cursor-pointer"
            :target="`invoice-row-${data.item.id}-send-icon`"
          /> -->

          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="
              $router.push({
                name: 'apps-transaction-edit',
                params: { id: data.item.parent_trans_id ? data.item.parent_trans_id : data.item.id }
              })
            "
          />
          <b-tooltip
            :title="t('Preview')"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              :to="{
                name: 'apps-transaction-edit',
                params: { id: data.item.parent_trans_id ? data.item.parent_trans_id : data.item.id }
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ t('Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="promptDialog(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ t('Delete') }}</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item>
              <feather-icon icon="CopyIcon" />
              <span class="align-middle ml-50">{{ t('Duplicate') }}</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ t('Showing') }} {{ dataMeta.from }} {{ t('to') }}
            {{ dataMeta.to }} {{ t('of') }} {{ dataMeta.of }}
            {{ t('entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
// import { onUnmounted } from '@vue/composition-api'
// import store from '@/store'
import useTransactionList from './useTransactionList'

// import transactionStoreModule from '../transactionStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    flatPickr,
    vSelect
  },
  methods: {
    promptDialog(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {
            this.deleteTransaction(id)
          }
        })
    }
  },
  setup() {
    // const TRANSACTION_APP_STORE_MODULE_NAME = 'app-transaction'

    // // Register module
    // if (!store.hasModule(TRANSACTION_APP_STORE_MODULE_NAME)) store.registerModule(TRANSACTION_APP_STORE_MODULE_NAME, transactionStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(TRANSACTION_APP_STORE_MODULE_NAME)) store.unregisterModule(TRANSACTION_APP_STORE_MODULE_NAME)
    // })

    const statusOptions = [
      // 'New',
      'Pending',
      'Success',
      'Canceled'
    ]
    const roundUp = num => {
      const x = num ? Math.round(num * 10) / 10 : 0
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      return parts.join('.')
    }

    const {
      fetchTransaction,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
      deleteTransaction,
      t,
      rangePicker,
      flatPickerChange
    } = useTransactionList()

    return {
      fetchTransaction,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,

      deleteTransaction,
      t,
      rangePicker,
      flatPickerChange,
      roundUp
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
