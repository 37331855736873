import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTransactionList2() {
  const { t } = useI18nUtils()
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)
  const tDefaults = [
    { key: 'id', label: '#', sortable: true },
    { key: 'client_name', label: 'Имя', sortable: true },
    { key: 'nam_doljni', label: 'Нам должны', sortable: true },
    { key: 'mi_doljni', label: 'Мы должны', sortable: true },
    { key: 'balance', label: 'Баланс', sortable: true },
  ]
  // Table Handlers
  const tableColumns = ref(tDefaults)

  const queryFields = [
    { label: 'id', whereKeys: ['id'], sortKey: 'id' },
    { label: 'client_name', whereKeys: ['client_name'], sortKey: 'client_name' },
    { label: 'nam_doljni', whereKeys: ['nam_doljni'], sortKey: 'nam_doljni' },
    { label: 'mi_doljni', whereKeys: ['mi_doljni'], sortKey: 'mi_doljni' },
    { key: 'balance', whereKeys: ['balance'], sortKey: 'balance' },
  ]

  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('client_name')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const q = (val, joiner) => {
    const where = []
    queryFields.forEach(field => {
      field.whereKeys.forEach(column => {
        where.push(`(${column},like,~${val}~)`)
      })
    })
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }

  const fetchTransaction2 = (ctx, callback) => {
    console.log(ctx.filter.activeSettle)
    const whereCond = (statusFilter.value !== null ? `(status,eq,${statusFilter.value})` : '') + (searchQuery.value !== '' ? q(searchQuery.value, statusFilter.value !== null) : '')
    // console.log(ctx.filter.kassSums.length)
    let fullWhere = whereCond
    if (ctx.filter.kassSums.length > 0 && ctx.filter.activeSettle !== null && ctx.filter.activeSettle !== 0) {
      if (whereCond.length > 0) {
        fullWhere = `(${whereCond})~and(type,eq,${ctx.filter.settlements[ctx.filter.activeSettle].types})`
      } else {
        fullWhere = `(type,eq,${ctx.filter.settlements[ctx.filter.activeSettle].types})`
        tableColumns.value = tDefaults
      }
    } else {
      tableColumns.value = tDefaults
    }

    store
      .dispatch('app-transaction/fetchTransaction2', {
        _size: perPage.value,
        _p: currentPage.value,
        _sort: (isSortDirDesc.value === true ? '-' : '') + queryFields.filter(item => item.label === sortBy.value)[0].sortKey,
        _where: fullWhere,
        _fields: '-',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'Settlements_view',
      })
      .then(response => {
        const { records, total, summary } = response.data

        callback(records)
        totalInvoices.value = total
        store.commit('app-transaction/UPDATE_SETTLE_SUMMARY', summary)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Pending') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Success') return { variant: 'success', icon: 'CheckCircleIcon' }
    // if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Canceled') return { variant: 'danger', icon: 'InfoIcon' }
    // if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    // if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Canceled') return 'danger'
    // if (status === 'Closed') return 'danger'
    // if (status === 'Downloaded') return 'secondary'
    if (status === 'Pending') return 'warning'
    // if (status === 'Sent') return 'info'
    if (status === 'Success') return 'success'
    return 'primary'
  }

  const deleteSale = id => {
    store.dispatch('app-transaction/deleteSale', { id })
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          refetchData()
        } else {
          // purchaseData.value = response.data
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          // purchaseData.value = undefined
        }
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchTransaction2,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
    deleteSale,
    t,
  }
}
