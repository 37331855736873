import { ref, watch, computed } from '@vue/composition-api'
import moment from 'moment'
import store from '@/store'

// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTransactionList() {
  const { t } = useI18nUtils()
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'type', label: 'Тип опер.', sortable: true },
    {
      key: 'date',
      label: 'Дата',
      sortable: true,
      width: '40px'
    },
    { key: 'subname', label: 'Счет', sortable: true },
    { key: 'name', label: 'Статья', sortable: true },
    { key: 'rec_user', label: 'Получил', sortable: true },
    { key: 'amount_usd', label: 'Сумма,USD', sortable: true },
    { key: 'amount_uzs', label: 'Сумма,UZS', sortable: true },
    { key: 'currency', label: 'Валюта', sortable: true },
    { key: 'rate', label: 'Курс', sortable: true },
    { key: 'commision', label: 'Комиссия%', sortable: true },
    // formatter: val => `${val}`
    { key: 'account_name', label: 'Касса', sortable: true },
    // { key: 'status', label: 'status', sortable: true },
    { key: 'balance', label: 'Баланс', sortable: true },
    { key: 'comments', label: 'Комментарии', sortable: true },
    { key: 'actions' }
  ]

  const queryFields = [
    { label: 'id', whereKeys: ['id'], sortKey: 'id' },
    // { label: 'status', whereKeys: ['status'], sortKey: 'status' },
    { label: 'type', whereKeys: ['type'], sortKey: 'type' },
    { label: 'name', whereKeys: ['name'], sortKey: 'name' },
    {
      key: 'account_name',
      whereKeys: ['account_name'],
      sortKey: 'account_name'
    },
    { key: 'rec_user', whereKeys: ['rec_user'], sortKey: 'rec_user' },
    { key: 'subname', whereKeys: ['subname'], sortKey: 'subname' },
    { label: 'amount_usd', whereKeys: ['amount_usd'], sortKey: 'amount_usd' },
    { label: 'amount_uzs', whereKeys: ['amount_uzs'], sortKey: 'amount_uzs' },
    { key: 'currency', whereKeys: ['currency'], sortKey: 'currency' },
    { key: 'rate', whereKeys: ['rate'], sortKey: 'rate' },
    { key: 'commision', whereKeys: ['commision'], sortKey: 'commision' },
    { label: 'balance', whereKeys: ['balance'], sortKey: 'balance' },
    { label: 'date', whereKeys: ['date'], sortKey: 'date' },
    { label: 'comments', whereKeys: ['comments'], sortKey: 'comments' }
  ]

  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const q = (val, joiner) => {
    const where = []
    queryFields.forEach(field => {
      field.whereKeys.forEach(column => {
        where.push(`(${column},like,~${val}~)`)
      })
    })
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }

  const flatPickerChange = val => {
    console.log(val)
    store.commit('app-transaction/UPDATE_RANGE', val)
  }
  // prettier-ignore
  const rangePicker = ref(store.state['app-transaction'].dateRange.length === 2 ? store.state['app-transaction'].dateRange : [])

  const fetchTransaction = (ctx, callback) => {
    console.log(ctx.filter.activeKassa)
    const whereCond = `${statusFilter.value !== null ? `(status,eq,${statusFilter.value})` : ''}${searchQuery.value !== '' ? q(searchQuery.value, statusFilter.value !== null) : ''}`

    // console.log(ctx.filter.kassSums.length)
    let fullWhere = whereCond
    if (ctx.filter.kassSums.length > 0 && ctx.filter.activeKassa !== null) {
      if (whereCond.length > 0) {
        fullWhere = ctx.filter.kassSums[ctx.filter.activeKassa].account_id !== 0 ? `(${whereCond})~and(account_id,eq,${ctx.filter.kassSums[ctx.filter.activeKassa].account_id})` : `(${whereCond})`
      } else {
        fullWhere = ctx.filter.kassSums[ctx.filter.activeKassa].account_id !== 0 ? `(account_id,eq,${ctx.filter.kassSums[ctx.filter.activeKassa].account_id})` : ''
      }
    }

    if (ctx.filter.dateRange.length === 2) {
      const sdate = moment(ctx.filter.dateRange[0]).format('YYYY-MM-DD')
      const edate = moment(ctx.filter.dateRange[1]).format('YYYY-MM-DD')

      fullWhere = `${fullWhere}${fullWhere.length > 0 ? '~and' : ''}((date,gte,${sdate})~and(date,lte,${edate}))`
    }
    store
      .dispatch('app-transaction/fetchTransaction', {
        _size: perPage.value,
        _p: currentPage.value,
        _sort: `${isSortDirDesc.value === true ? '-' : ''}${queryFields.filter(item => item.label === sortBy.value)[0].sortKey}`,
        _where: fullWhere,
        _fields: 'id,date,activity_id,to_sub_id,amount_usd,amount_uzs,balance,status,comments,currency,rate,name,related_to,type,subname,account_name,rec_user,commision,parent_trans_id',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'Transactions_view'
      })
      .then(response => {
        const { records, total, summary } = response.data

        callback(records)
        totalInvoices.value = total
        store.commit('app-transaction/UPDATE_KASSA_SUMMARY', summary)
      })
      .catch(errors => {
        console.log(errors)
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    switch (status) {
      case 'Pending':
        return { variant: 'warning', icon: 'PieChartIcon' }
      case 'Success':
        return { variant: 'success', icon: 'CheckCircleIcon' }
      case 'Canceled':
        return { variant: 'danger', icon: 'InfoIcon' }
      default:
        return { variant: 'secondary', icon: 'XIcon' }
    }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Canceled') return 'danger'
    // if (status === 'Closed') return 'danger'
    // if (status === 'Downloaded') return 'secondary'
    if (status === 'Pending') return 'warning'
    // if (status === 'Sent') return 'info'
    if (status === 'Success') return 'success'
    return 'primary'
  }

  const deleteTransaction = id => {
    store
      .dispatch('app-transaction/deleteTransaction', { id })
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно удалили строку.'
            }
          })
          // resetForm()
          refetchData()
        } else {
          // purchaseData.value = response.data
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          // purchaseData.value = undefined
        }
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchTransaction,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
    deleteTransaction,
    t,
    rangePicker,
    flatPickerChange
  }
}
